import styles from './checkoutLayout.module.css';
import { Container, Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Footer from '../../components/_shared/Footer';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const CheckoutLayout = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#262e3f',
      }}
    >
      <Helmet>
        <script type="text/javascript">{`(function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;g.parentNode.insertBefore(f,g);})('https://utt.impactcdn.com/A4295455-0357-4a4e-9f66-c9b0af026b201.js','script','ire',document,window); ire('identify', {customerid: '', customeremail: ''}); `}</script>
      </Helmet>
      <Helmet>
        <link
          rel="canonical"
          href="https://my.fitnescity.com/checkout/df703056-942d-4989-9118-db15d7c59586?_ics=1725469890228&irclickid=%7E6b760VOFKCzuvzFvyzFLCsuqge67YVUMHLPHIyzsoh98651VPHEv&order_id=10772ed1-5d9f-47ce-bf59-3bc687ddc720"
        />
      </Helmet>
      <CssBaseline />
      <Container maxWidth="xl" className={styles.checkout_wrapper}>
        <img
          className={styles.logo}
          src="/img/logo.png"
          title="fitnescity logo"
          onClick={(e: any) => {
            e.preventDefault();
            window.location.href = 'https://www.fitnescity.com/states';
          }}
        />
        <Outlet />
      </Container>
      <Footer />
    </Box>
  );
};

export default CheckoutLayout;
