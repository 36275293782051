import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  GetFormToSignResultType,
  FormToSignResultType,
  UpdateFormAsSignedResultType,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const formsApi = createApi({
  reducerPath: "formApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/admin`,
    prepareHeaders: (headers) => {
      headers.set("x-api-key", API_KEY);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getFormToSign: builder.query<FormToSignResultType, string>({
      query(signature_request_id) {
        return {
          url: `forms/sign-url/${signature_request_id}`,
          method: "GET",
        };
      },
      transformResponse: (result: GetFormToSignResultType) => {
        return result.data;
      },
    }),
    updateFormAsSigned: builder.mutation<UpdateFormAsSignedResultType, any>({
      query(payload) {
        return {
          url: `/forms/signed`,
          method: "POST",
          body: payload,
        };
      },
      transformResponse: (result: any) => {
        return result.data;
      },
    }),
  }),
});

export const { useGetFormToSignQuery, useUpdateFormAsSignedMutation } =
  formsApi;
